.list {
  padding-bottom: 20px;
  padding-top: 10px;
}

.listItem {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
  margin-right: 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
}

.listItem.isActive {
  color: #138622;
}

.separator {
  border-bottom: 1px solid #e3e3e3;
  margin: 0 10px;
  flex: 1;
}
