.container {
}

.modalContainer,
.button {
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 0 12px;
  z-index: 1;
  transition: top 0.5s;
}

.modal {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalContainer {
  margin: 119px 20px 0;
  position: relative;
  animation-name: modalFade;
  animation-duration: 0.3s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.modalOverlay {
}

.icon {
  margin-left: 6px;
}

@keyframes modalFadeIn {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
